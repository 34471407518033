export const TOKEN = "token";
export const BEARER = "bearer";
export const REFRESH_TOKEN = "refresh_token";
export const PREFIX_GOOGLE = "https://docs.google.com/viewer?url=";
export const PREFIX_URL = "https://view.officeapps.live.com/op/embed.aspx?src=";
export const LIMIT2 = 2;
export const LIMIT20 = 20;
export const LIMIT10 = 10;
export const LIMIT100 = 100;
export const MIN_PRICE = 10000;
export const MAX_PRICE = 100000000;
export const EMAIL_REGEX = /^[\w+][\w\.\-]+@[\w\-]+(\.\w{2,10})+$/
export const PHONE_REGEX = /^(?:[+0]9)?[0-9]{10}$/
export const Path = {
  USER_LIST: "users",
  // course
  MB_COURSE: "mb-course",
  ALL_COURSE: "all-course",
  MY_COURSE: "my-course",
  // banner
  BANNER: "banner",
  PARTNER: "partner",
  USER_REGISTRY: "user-registry",
  // marketing chanel
  PACKAGE: "package",
  COMBO: "combo",
  MARKETING_FORM: "marketing-form",
  // template slide
  TEMPLATE_SLIDE: "template-slide",
  BUSINESS: "business",
  EDUCATION: "education",
  FINANCE_AND_BANKING: "finance-and-banking",
  TECHNOLOGY: "technology",
  INDUSTRY: "industry",
  MARKETING: "marketing",
  MEDICAL: "medical",
  FOOD: "food",
  REAL_ESTATE: "real-estate",
  RELIGION: "religion",
  SPORT: "sport",
  TRAVEL: "travel",
  // news
  NEWS: "news",
  SOURCE: "source",
  // version
  VERSION: "version",
  BILL: "bill"
};

export const MODAL = {
  ADD: "add",
  EDIT: "edit",
  CONFIRM: "confirm",
};

export const PriorityKey = {
  ON: true,
  OFF: false,
}
export const FilterPriorityOption = [
  { value: "ALL", label: "All" },
  { value: PriorityKey.ON, label: "On" },
  { value: PriorityKey.OFF, label: "Off" },
]


export const ProductKey = {
  COMBO: "COMBO",
  COURSE: "COURSE",
  SLIDE: "SLIDE"
};

export const ProductText = {
  [ProductKey.COMBO]: "Combo",
  [ProductKey.COURSE]: "Khóa học",
  [ProductKey.SLIDE]: "Slide"
}

export const FilterProductOption = [
  { value: "ALL", label: "All" },
  { value: ProductKey.COURSE, label: ProductText[ProductKey.COURSE] },
  { value: ProductKey.SLIDE, label: ProductText[ProductKey.SLIDE] },
  // { value: BillStatusKey.FAIL, label: BillStatusText[BillStatusKey.FAIL] },
]

export const CategoryText = {
  [Path.BUSINESS]: "Business",
  [Path.EDUCATION]: "Education",
  [Path.FINANCE_AND_BANKING]: "Finance and Banking",
  [Path.TECHNOLOGY]: "Technology",
  [Path.INDUSTRY]: "Industry",
  [Path.MARKETING]: "Marketing",
  [Path.MEDICAL]: "Medical",
  [Path.FOOD]: "Food",
  [Path.REAL_ESTATE]: "Real Estate",
  [Path.RELIGION]: "Religion",
  [Path.SPORT]: "Sport",
  [Path.TRAVEL]: "Travel",
};

export const CourseType = {
  SCHOOL: "",
  MB_COURSE: "MB_COURSE",
  PARTNER_COURSE: "PARTNER_COURSE",
}

export const CourseTypeText = {
  [CourseType.SCHOOL]: "Khóa học DUE",
  [CourseType.MB_COURSE]: "Khóa học MB",
  [CourseType.PARTNER_COURSE]: "Khóa học Đối tác",
}

export const FilterCourseTypeOption = [
  { value: "ALL", label: "All" },
  { value: CourseType.SCHOOL, label: CourseTypeText[CourseType.SCHOOL] },
  { value: CourseType.MB_COURSE, label: CourseTypeText[CourseType.MB_COURSE] },
  { value: CourseType.PARTNER_COURSE, label: CourseTypeText[CourseType.PARTNER_COURSE] },
]
export const CategoryValue = [
  "Business",
  "Education",
  "Finance and Banking",
  "Technology",
  "Industry",
  "Marketing",
  "Medical",
  "Food",
  "Real Estate",
  "Religion",
  "Sport",
  "Travel",
];

export const RoleMapping = {
  MB_ADMIN: "MB_ADMIN",
  ADMIN: "ADMIN",
  TEACHER: "TEACHER",
  USER: "USER",
  PARTNER_ADMIN: "PARTNER_ADMIN"
};

export const RoleText = {
  [RoleMapping.MB_ADMIN]: "MB Admin",
  [RoleMapping.ADMIN]: "Admin",
  [RoleMapping.TEACHER]: "Teacher",
  [RoleMapping.USER]: "User",
  [RoleMapping.PARTNER_ADMIN]: "Partner Admin"
};

export const userRole = RoleMapping.ADMIN;
export const RoleValue = [
  { role: RoleMapping.MB_ADMIN, value: RoleMapping.MB_ADMIN },
  { role: RoleMapping.ADMIN, value: RoleMapping.ADMIN },
  { role: RoleMapping.PARTNER_ADMIN, value: RoleMapping.PARTNER_ADMIN },
  { role: RoleMapping.TEACHER, value: RoleMapping.TEACHER },
  { role: RoleMapping.USER, value: RoleMapping.USER },

];

export const StatusKey = {
  ACTIVE: "ACTIVE",
  PENDING: "PENDING",
  DEACTIVE: "DEACTIVE",
  DISABLE: "DISABLE",
};

export const StatusText = {
  [StatusKey.ACTIVE]: "Active",
  [StatusKey.PENDING]: "Pending",
  [StatusKey.DEACTIVE]: "Inactive",
  [StatusKey.DISABLE]: "Disable",
};

export const StatusValue = [
  { status: StatusKey.DEACTIVE, value: 0 },
  { status: StatusKey.ACTIVE, value: 1 },
  { status: StatusKey.PENDING, value: 2 },
  { status: StatusKey.DISABLE, value: 3 },
];

export const BannerStatusKey = {
  ACTIVE: "ACTIVE",
  INACTIVE: "INACTIVE",
}

export const BillStatusKey = {
  INPROGRESS: "PROCESSING",
  SUCCESS: "SUCCESS",
  FAIL: "FAILURE",
}

export const BillStatusText = {
  [BillStatusKey.INPROGRESS]: "Inprogress",
  [BillStatusKey.SUCCESS]: "Success",
  [BillStatusKey.FAIL]: "Failed",
  [BillStatusKey.PENDING]: "Pending",
}

export const FilterBillStatusOption = [
  { value: "ALL", label: "All" },
  { value: BillStatusKey.INPROGRESS, label: BillStatusText[BillStatusKey.INPROGRESS] },
  { value: BillStatusKey.SUCCESS, label: BillStatusText[BillStatusKey.SUCCESS] },
  { value: BillStatusKey.FAIL, label: BillStatusText[BillStatusKey.FAIL] },
]

export const PartnerStatusKey = {
  ACTIVE: "ACTIVE",
  INACTIVE: "INACTIVE",
}

export const styleBillStatus = {
  [BillStatusKey.INPROGRESS]: "info",
  [BillStatusKey.SUCCESS]: "success",
  [BillStatusKey.FAIL]: "danger",
}

export const PartnerStatusText = {
  [PartnerStatusKey.ACTIVE]: "Active",
  [PartnerStatusKey.INACTIVE]: "Inactive",
}

export const BannerStatusText = {
  [BannerStatusKey.ACTIVE]: "Active",
  [BannerStatusKey.INACTIVE]: "Inactive"
}

export const styleStatus = {
  [BannerStatusKey.ACTIVE]: "info",
  [BannerStatusKey.INACTIVE]: "danger",
}

export const UserRegisterStatusKey = {
  SEND: "SEND",
  NEW: "NEW",
}

export const styleUserRegisterStatus = {
  [UserRegisterStatusKey.SEND]: "success",
  [UserRegisterStatusKey.NEW]: "info",
}

export const UserRegisterStatusText = {
  [UserRegisterStatusKey.SEND]: "Đã gửi",
  [UserRegisterStatusKey.NEW]: "Mới",
}

export const SourceKey = {
  WEBSITE: "WEBSITE",
};

export const SourceOptions = [{ value: SourceKey.WEBSITE, label: "Website" }];

export const TopicKey = {
  BANKING: "Banking and Finance",
  PFM: "PFM",
  FINTECH: "Fintech",
};

export const DeviceKey = {
  ANDROID: "ANDROID",
  IOS: "IOS",
};

export const TestKey = {
  TEST: "TEST",
  QUIZ: "QUIZ",
};

export const QUESTION_CODE = {
  CHOOSE_MANY: "CHOOSE_MANY",
  CHOOSE_ONE: "CHOOSE_ONE"
}

export const QUESTION_TYPE = {
  [QUESTION_CODE.CHOOSE_MANY]: "Câu hỏi nhiều đáp án",
  [QUESTION_CODE.CHOOSE_ONE]: "Câu hỏi trắc nghiệm"
}

export const TestValue = [
  { type: TestKey.QUIZ, value: 0 },
  { type: TestKey.TEST, value: 1 },
];

export const DeviceValue = [
  { type: DeviceKey.ANDROID, value: 0 },
  { type: DeviceKey.IOS, value: 1 },
];

export const CourseTopic = [
  {
    id: "banking",
    value: "Banking and Finance",
  },
  {
    id: "pfm",
    value: "PFM",
  },
  {
    id: "fintech",
    value: "Fintech",
  },
];

export const TopicText = {
  [TopicKey.BANKING]: "Banking and Finance",
  [TopicKey.PFM]: "PFM",
  [TopicKey.FINTECH]: "Fintech",
};

export const TopicValue = [
  { topic: TopicKey.BANKING, value: 0 },
  { topic: TopicKey.PFM, value: 1 },
  { topic: TopicKey.FINTECH, value: 2 },
];
export const UserKey = {
  ID: "id",
  NAME: "name",
  EMAIL: "email",
  SCHOOL_ID: "schoolId",
  ROLE: "role",
  STATUS: "status",
  STUDENT_ID: "studentId",
  SCHOOL_NAME: "schoolName",
  MAJOR_NAME: "majorName",
  CLASS_NAME: "className",
};

export const UserLabel = {
  ID: "Id",
  NAME: "Name",
  EMAIL: "Email Adress",
  SCHOOL_ID: "School Id",
  ROLE: "Role",
  STATUS: "Status",
  STUDENT_ID: "Student Id",
  SCHOOL_NAME: "School Name",
  MAJOR_NAME: "Major",
  CLASS_NAME: "Class",
};

export const UserInfoFields = [
  { key: UserKey.ID, label: UserLabel.ID },
  { key: UserKey.NAME, label: UserLabel.NAME },
  { key: UserKey.EMAIL, label: UserLabel.EMAIL },
  { key: UserKey.SCHOOL_ID, label: UserLabel.SCHOOL_ID },
  { key: UserKey.ROLE, label: UserLabel.ROLE },
  { key: UserKey.STATUS, label: UserLabel.STATUS },
  { key: UserKey.STUDENT_ID, label: UserLabel.STUDENT_ID },
  { key: UserKey.SCHOOL_NAME, label: UserLabel.SCHOOL_NAME },
  { key: UserKey.MAJOR_NAME, label: UserLabel.MAJOR_NAME },
  { key: UserKey.CLASS_NAME, label: UserLabel.CLASS_NAME },
];

export const FilterRoleOptions = [
  { value: "ALL", label: "All" },
  { value: RoleMapping.MB_ADMIN, label: "MB Admin" },
  { value: RoleMapping.ADMIN, label: "Admin" },
  { value: RoleMapping.PARTNER_ADMIN, label: "Partner Admin" },
  { value: RoleMapping.TEACHER, label: "Teacher" },
  { value: RoleMapping.USER, label: "User" },
];

export const FilterStatusOptions = [
  { value: "ALL", label: "All" },
  { value: StatusKey.ACTIVE, label: "Active" },
  { value: StatusKey.PENDING, label: "Pending" },
  { value: StatusKey.DEACTIVE, label: "Inactive" },
];

export const FilterCourseStatusOptions = [
  { value: "ALL", label: "All" },
  { value: StatusKey.ACTIVE, label: "Active" },
  { value: StatusKey.PENDING, label: "Pending" },
  { value: StatusKey.DEACTIVE, label: "Inactive" },
  // { value: StatusKey.DISABLE, label: "Disable" }
];

export const FilterUserRegisterStatusOptions = [
  { value: "ALL", label: "All" },
  { value: UserRegisterStatusKey.SEND, label: "Đã gửi" },
  { value: UserRegisterStatusKey.NEW, label: "Mới" },
]

export const FilterSlideStatusOptions = [
  { value: "ALL", label: "All" },
  { value: StatusKey.ACTIVE, label: "Active" },
  { value: StatusKey.DEACTIVE, label: "Inactive" },
];

export const FilterBannerStatusOption = [
  { value: "ALL", label: "All" },
  { value: BannerStatusKey.ACTIVE, label: "Active" },
  { value: BannerStatusKey.INACTIVE, label: "Inactive" },
]
export const FilterPartnerStatusOption = [
  { value: "ALL", label: "All" },
  { value: PartnerStatusKey.ACTIVE, label: "Active" },
  { value: PartnerStatusKey.INACTIVE, label: "Inactive" },
]

export const FilterNewsTypeOptions = [
  { value: "ALL", label: "All" },
  { value: "USER_ENTERED", label: "Tự tạo" },
  { value: "WEBSITE", label: "Craw" },
];

export const FilterCourseTopicOptions = [
  { value: "ALL", label: "All" },
  { value: TopicKey.BANKING, label: "Banking and Finance" },
  { value: TopicKey.PFM, label: "PFM" },
  { value: TopicKey.FINTECH, label: "Fintech" },
];

export const FilterPartnerService = [
  { value: "ALL", label: "All" },
  { value: "Banner quảng cáo", label: "Banner quảng cáo" },
]

export const FilterDeviceOptions = [
  { value: "ALL", label: "All" },
  { value: DeviceKey.ANDROID, label: "Android" },
  { value: DeviceKey.IOS, label: "IOS" },
];


export const FilterQuizOptions = [
  { value: "ALL", label: "All" },
  { value: TestKey.TEST, label: "Test" },
  { value: TestKey.QUIZ, label: "Quiz" },
];

export const FilterYNOptions = [
  { value: "ALL", label: "All" },
  { value: "Yes", label: "Yes" },
  { value: "No", label: "No" },
];

export const FilterNewsSourceStatusOptions = [
  { value: "ALL", label: "All" },
  { value: StatusKey.ACTIVE, label: "Active" },
  { value: StatusKey.DEACTIVE, label: "Inactive" },
];

export const FilterSourceAutoPostOptions = [
  { value: "ALL", label: "All" },
  { value: "Yes", label: "Yes" },
  { value: "No", label: "No" },
];

export const FilterSourceTopicOptions = [
  { value: "ALL", label: "All" },
  { value: TopicKey.BANKING, label: "Banking and Finance" },
  { value: TopicKey.PFM, label: "PFM" },
  { value: TopicKey.FINTECH, label: "Fintech" },
];
